import React, { useEffect, useState } from "react";
import ArticleVideosTable from "./ArticleVideosTable";
import { Colors, Fonts } from "../../styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BaseInputField from "../../components/Inputs/BaseInput";
import TablePagination from "../../components/Common/TablePagination";
import { resultsPerPage } from "../../constants/pagination";
import { useQuery } from "@apollo/client";
import { GET_ARTICLE_VIDEOS } from "../../graphql/queries";
import { useTranslation } from "react-i18next";
import ArticlesIcon from "../../components/Icons/Articles";
import ArticleVideosForm from "./ArticleVideosForm";

const useStyles = makeStyles(() => ({
  headerContainer: {
    margin: "40px 0",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: 500,
    "& div": {
      marginTop: 0,
    },
  },
  contentContainer: {
    padding: "1rem 5rem",
    flex: 1,
    maxWidth: "70vw",
  },
  userContainer: {
    marginLeft: 300,
  },
}));

const ArticleVideos = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [articleVideosData, setArticleVideosData] = useState([]);
  const [originalArticlesVideosData, setOriginalArticlesVideosData] = useState(
    []
  );
  const [createArticleVideo, setCreateArticleVideo] = useState(false);
  const [editingArticleVideoId, setEditingArticleVideoId] = useState(null);
  const [maxPages, setMaxPages] = useState(0);
  const { t } = useTranslation();
  const { data: articleVideos, refetch: refetchArticleVideos } =
    useQuery(GET_ARTICLE_VIDEOS);

  useEffect(() => {
    document.title = t("articleVideos.title");
  }, [t]);

  useEffect(() => {
    setOriginalArticlesVideosData(
      articleVideos
        ? JSON.parse(JSON.stringify(articleVideos.articleVideos))
        : []
    );
    setArticleVideosData(
      articleVideos
        ? JSON.parse(JSON.stringify(articleVideos.articleVideos))
        : []
    );
    setMaxPages(articleVideos ? articleVideos.articleVideos.length : 1);
    setCurrentPage(1);
  }, [articleVideos]);

  useEffect(() => {
    const startIndex =
      currentPage === 1 ? 0 : (currentPage - 1) * resultsPerPage;
    const endIndex =
      currentPage === 1 ? resultsPerPage : currentPage * resultsPerPage;
    if (!searchValue.trim()) {
      setArticleVideosData(
        JSON.parse(
          JSON.stringify(originalArticlesVideosData.slice(startIndex, endIndex))
        )
      );
      setMaxPages(
        Math.ceil(originalArticlesVideosData.length / resultsPerPage)
      );
    } else {
      const searchedValues = originalArticlesVideosData.filter((data) => {
        return (
          data.title.indexOf(searchValue) > -1 ||
          (data?.articleCategories
            ? data?.articleCategories.name.indexOf(searchValue) > -1
            : false)
        );
      });
      setArticleVideosData(searchedValues.slice(startIndex, endIndex));
      setMaxPages(Math.ceil(searchedValues.length / resultsPerPage));
    }
  }, [currentPage, originalArticlesVideosData, searchValue, maxPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const handlePrevPageClick = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const onFormSubmit = async () => {
    await refetchArticleVideos();
    setCreateArticleVideo(false);
    setEditingArticleVideoId(null);
  };

  return (
    <Box className={classes.userContainer}>
      <Grid container>
        <Box className={classes.contentContainer}>
          <Grid container className={classes.headerContainer}>
            <ArticlesIcon
              height={40}
              width={40}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.title}>
              {createArticleVideo
                ? t("articleVideos.createNewHeader")
                : t("articleVideos.header")}
            </Typography>
          </Grid>
          {createArticleVideo || editingArticleVideoId ? (
            <ArticleVideosForm
              onFormSubmit={onFormSubmit}
              articleVideoId={editingArticleVideoId}
            />
          ) : (
            <>
              <Box className={classes.filterContainer}>
                <Box className={classes.inputContainer}>
                  <BaseInputField
                    id="search-value"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder={t("articleVideos.searchPlaceholder")}
                  />
                </Box>
                <PrimaryButton onClick={() => setCreateArticleVideo(true)}>
                  {t("articleVideos.addNewButton")}
                </PrimaryButton>
              </Box>
              <ArticleVideosTable
                toggleEdit={(id) => setEditingArticleVideoId(id)}
                results={articleVideosData.map((articleVideo) => {
                  return {
                    id: articleVideo.id,
                    title: articleVideo.title,
                    category: articleVideo?.articleCategories?.name,
                  };
                })}
              />
              <TablePagination
                prevDisabled={currentPage === 1}
                nextDisabled={currentPage >= maxPages}
                onPrevPageClick={handlePrevPageClick}
                onNextPageClick={handleNextPageClick}
              />
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default ArticleVideos;
