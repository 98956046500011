import { gql } from "@apollo/client";

export default gql`
  query GetRelaxAudioCategories {
    relaxAudioCategories {
      id
      name
    }
  }
`;
