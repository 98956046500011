import logo from "../../assets/images/twoact@2x.png";
import profile from "../../assets/images/profile_icn.svg";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import MenuDown from "../../components/Icons/MenuDown";
import UserMenu from "./UserMenu";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
    backgroundColor: Colors.COLOR_WHITE,
    borderBottom: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: 0,
    height: 40,
    border: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    margin: "0 15px",
  },
  logoText: {
    textTransform: "uppercase",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_LOGO,
  },
  schoolText: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    color: Colors.COLOR_LABEL,
    marginRight: 20,
    marginLeft: 20,
  },
  logo: {
    width: 122,
  },
  profileImage: {
    width: 40,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  menuDownIcon: {
    marginLeft: 10,
    transform: "rotate(90deg)",
  },
  translationButton: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    color: Colors.COLOR_LABEL,
  },
  translationButtonRight: {
    borderLeft: `1px solid ${Colors.COLOR_LABEL}`,
    margin: "0 10px",
    paddingLeft: 10,
  },
  activeButton: {
    color: Colors.COLOR_PRIMARY_BUTTON,
  },
}));

const Header = ({ onLogOut }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { t } = useTranslation();

  const handleLogOut = () => {
    onLogOut();
  };

  return (
    <Box className={classes.headerContainer}>
      <Box className={classes.logoContainer}>
        <img src={logo} alt="logo" className={classes.logo} />
        <Box className={classes.divider} />
        <Typography className={classes.logoText}>{t("menu.title")}</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        {pathname !== "/" && (
          <>
            <img src={profile} alt="profile" className={classes.profileImage} />
            <ButtonBase
              className={classes.menuDownIcon}
              disableRipple={true}
              onClick={() => setUserMenuOpen((prevState) => !prevState)}
            >
              <MenuDown
                height={24}
                width={24}
                color={Colors.COLOR_PRIMARY_BUTTON}
              />
            </ButtonBase>
            {userMenuOpen && <UserMenu logOut={handleLogOut} />}
          </>
        )}
      </Box>
    </Box>
  );
};

Header.propTypes = {
  onLogOut: PropTypes.func.isRequired,
};

export default Header;
