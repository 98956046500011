import { gql } from "@apollo/client";

export default gql`
  query GetTipsTricksById($id: Float!) {
    tipsTrick(id: $id) {
      id
      title
      englishTitle
      content
      englishContent
      externalUrl
      imageUrl
      tipsTricksTypesId
      tipsTricksCategoriesId
    }
  }
`;
