import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Colors, Fonts } from "../../styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  paginationButtonContainer: {
    display: "flex",
  },
  buttonBase: {
    textTransform: "none",
  },
  buttonText: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_PRIMARY_BUTTON,
  },
  disabledButtonText: {
    opacity: 0.5,
  },
  prevPageButton: {
    paddingRight: 20,
  },
  nextPageButton: {
    borderLeft: `1px solid ${Colors.COLOR_GRAY}`,
    paddingLeft: 20,
  },
}));

const TablePagination = ({
  prevDisabled,
  nextDisabled,
  onPrevPageClick,
  onNextPageClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.paginationContainer}>
      <Box className={classes.paginationButtonContainer}>
        <ButtonBase
          className={classNames(
            prevDisabled ? classes.disabledButtonText : "",
            classes.prevPageButton,
            classes.buttonBase
          )}
          disableRipple={true}
          onClick={onPrevPageClick}
          disabled={prevDisabled}
        >
          <Typography className={classNames(classes.buttonText)}>
            {t("tipsTricksCategories.prevButton")}
          </Typography>
        </ButtonBase>
        <ButtonBase
          className={classNames(
            nextDisabled ? classes.disabledButtonText : "",
            classes.nextPageButton,
            classes.buttonBase
          )}
          disableRipple={true}
          onClick={onNextPageClick}
          disabled={nextDisabled}
        >
          <Typography className={classNames(classes.buttonText)}>
            {t("tipsTricksCategories.nextButton")}
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

TablePagination.propTypes = {
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  onPrevPageClick: PropTypes.func,
  onNextPageClick: PropTypes.func,
};

export default TablePagination;
