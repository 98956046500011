import React, { useEffect, useState } from "react";
import TipsTricksCategoriesTable from "./TipsTricksCategoriesTable";
import { Colors, Fonts } from "../../styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BaseInputField from "../../components/Inputs/BaseInput";
import TablePagination from "../../components/Common/TablePagination";
import { resultsPerPage } from "../../constants/pagination";
import { useQuery } from "@apollo/client";
import { GET_TIPS_TRICKS_CATEGORIES } from "../../graphql/queries";
import { useTranslation } from "react-i18next";
import TipsTricks from "../../components/Icons/TipsTricks";
import TipsTricksCategoriesForm from "./TipsTricksCategoriesForm";

const useStyles = makeStyles(() => ({
  headerContainer: {
    margin: "40px 0",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: 500,
    "& div": {
      marginTop: 0,
    },
  },
  contentContainer: {
    padding: "1rem 5rem",
    flex: 1,
    maxWidth: "70vw",
  },
  userContainer: {
    marginLeft: 300,
  },
}));

const TipsTricksCategories = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tipsTricksCategoriesData, setTipsTricksCategoriesData] = useState([]);
  const [
    originalTipsTricksCategoriesData,
    setOriginalTipsTricksCategoriesData,
  ] = useState([]);
  const [maxPages, setMaxPages] = useState(0);
  const [createCategory, setCreateCategory] = useState(false);
  const { t } = useTranslation();
  const [editingTipsTricksCategoriesId, setEditingTipsTricksCategoriesId] =
    useState(null);
  const { data: tipsTricksCategories, refetch: refetchTipsTricksCategories } =
    useQuery(GET_TIPS_TRICKS_CATEGORIES);

  useEffect(() => {
    document.title = t("tipsTricksCategories.title");
  }, [t]);

  useEffect(() => {
    setOriginalTipsTricksCategoriesData(
      tipsTricksCategories
        ? JSON.parse(JSON.stringify(tipsTricksCategories.tipsTricksCategories))
        : []
    );
    setTipsTricksCategoriesData(
      tipsTricksCategories
        ? JSON.parse(JSON.stringify(tipsTricksCategories.tipsTricksCategories))
        : []
    );
    setMaxPages(
      tipsTricksCategories
        ? tipsTricksCategories.tipsTricksCategories.length
        : 1
    );
    setCurrentPage(1);
  }, [tipsTricksCategories]);

  useEffect(() => {
    const startIndex =
      currentPage === 1 ? 0 : (currentPage - 1) * resultsPerPage;
    const endIndex =
      currentPage === 1 ? resultsPerPage : currentPage * resultsPerPage;
    if (!searchValue.trim()) {
      setTipsTricksCategoriesData(
        JSON.parse(
          JSON.stringify(
            originalTipsTricksCategoriesData.slice(startIndex, endIndex)
          )
        )
      );
      setMaxPages(
        Math.ceil(originalTipsTricksCategoriesData.length / resultsPerPage)
      );
    } else {
      const searchedValues = originalTipsTricksCategoriesData.filter((data) => {
        return data.name.indexOf(searchValue) > -1;
      });
      setTipsTricksCategoriesData(searchedValues.slice(startIndex, endIndex));
      setMaxPages(Math.ceil(searchedValues.length / resultsPerPage));
    }
  }, [currentPage, originalTipsTricksCategoriesData, searchValue, maxPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const handlePrevPageClick = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const onFormSubmit = async () => {
    await refetchTipsTricksCategories();
    setCreateCategory(false);
    setEditingTipsTricksCategoriesId(null);
  };

  return (
    <Box className={classes.userContainer}>
      <Grid container>
        <Box className={classes.contentContainer}>
          <Grid container className={classes.headerContainer}>
            <TipsTricks
              height={40}
              width={40}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.title}>
              {createCategory
                ? t("tipsTricksCategories.createNewHeader")
                : t("tipsTricksCategories.header")}
            </Typography>
          </Grid>
          {createCategory || editingTipsTricksCategoriesId ? (
            <TipsTricksCategoriesForm
              onFormSubmit={onFormSubmit}
              tipsTricksCategoryId={editingTipsTricksCategoriesId}
            />
          ) : (
            <>
              <Box className={classes.filterContainer}>
                <Box className={classes.inputContainer}>
                  <BaseInputField
                    id="search-value"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder={t("tipsTricksCategories.searchPlaceholder")}
                  />
                </Box>
                <PrimaryButton onClick={() => setCreateCategory(true)}>
                  {t("tipsTricksCategories.addNewButton")}
                </PrimaryButton>
              </Box>
              <TipsTricksCategoriesTable
                toggleEdit={(id) => setEditingTipsTricksCategoriesId(id)}
                results={tipsTricksCategoriesData.map((tipsTricksCategory) => {
                  return {
                    id: tipsTricksCategory.id,
                    name: tipsTricksCategory.name,
                    englishName: tipsTricksCategory.englishName,
                    iconUrl: tipsTricksCategory.iconUrl,
                    description: tipsTricksCategory.description,
                  };
                })}
              />
              <TablePagination
                prevDisabled={currentPage === 1}
                nextDisabled={currentPage >= maxPages}
                onPrevPageClick={handlePrevPageClick}
                onNextPageClick={handleNextPageClick}
              />
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default TipsTricksCategories;
