import { gql } from "@apollo/client";

export default gql`
  query GetArticleVideos {
    articleVideos(filter: { include: ["articleCategories"] }) {
      id
      title
      videoUrl
      thumbnailUrl
      description
      articleTypesId
      articleCategoriesId
      articleCategories {
        name
      }
    }
  }
`;
