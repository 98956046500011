import { gql } from "@apollo/client";

export default gql`
  mutation UpdateTipsTricks(
    $id: Float!
    $title: String!
    $englishTitle: String!
    $content: String!
    $englishContent: String!
    $externalUrl: String!
    $imageUrl: String!
    $tipsTricksCategoriesId: Float!
  ) {
    patchTipsTrick(
      id: $id
      tipsTricksPartialInput: {
        title: $title
        englishTitle: $englishTitle
        content: $content
        englishContent: $englishContent
        externalUrl: $externalUrl
        imageUrl: $imageUrl
        tipsTricksCategoriesId: $tipsTricksCategoriesId
      }
    ) {
      id
    }
  }
`;
