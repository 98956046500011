import PropTypes from "prop-types";

const Articles = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 23.69 25.666"
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M1298.42,200.176V183.357h5.081v16.819c0,1.96-1.133,3.538-2.541,3.538S1298.42,202.136,1298.42,200.176Z"
          transform="translate(-1279.811 -178.048)"
        />
        <path
          className="b"
          d="M1.118,25.656A1.192,1.192,0,0,1,0,24.4V6.731H5.12V5.825h-.3V3.751h.3V5.824H18.327V3.751H5.12V0L19.8.029V24.9a2.383,2.383,0,0,0,.508.453,1.777,1.777,0,0,0,.883.3H19.8Zm10.1-4.232V24.96h6.9V21.424Zm-9.5,2.125v.886h8.906v-.886Zm0-2.125v.885H10v-.885ZM4.8,10.5a.877.877,0,0,0-.847.9v6.282a.877.877,0,0,0,.847.9h9.943a.876.876,0,0,0,.846-.9V11.4a.876.876,0,0,0-.846-.9ZM2.761,7.6V8.48H18V7.6ZM8.3,16.623V12.461l3.386,2.08L8.3,16.622Z"
          transform="translate(0 0.01)"
        />
        <path
          className="a"
          d="M1223.394,168.788h-5.174l5.174-6.741Z"
          transform="translate(-1218.217 -162.047)"
        />
      </g>
    </svg>
  );
};

Articles.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Articles;
