import { gql } from "@apollo/client";

export default gql`
  query GetArticleCategories {
    articleCategories {
      id
      name
      englishName
      iconUrl
      description
    }
  }
`;
