import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  tableRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 40px 10px 40px",
    backgroundColor: Colors.COLOR_WHITE,
    "&:nth-child(even)": {
      backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
    },
  },
  textLink: {
    color: Colors.COLOR_PRIMARY_BUTTON,
    cursor: "pointer",
  },
  text: {
    fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
  },
  column: {
    flex: "30%",
  },
  columnSmall: {
    display: "flex",
    flex: "10%",
    position: "relative",
  },
  image: {
    height: 30,
  },
}));

const ArticlesTableRow = ({ title, category, toggleEdit }) => {
  const classes = useStyles();
  return (
    <Box className={classes.tableRowContainer}>
      <Box className={classes.column}>
        <Typography
          className={classNames(classes.text, classes.textLink)}
          onClick={toggleEdit}
        >
          {title}
        </Typography>
      </Box>
      <Box className={classes.column}>
        <Typography className={classes.text}>{category}</Typography>
      </Box>
    </Box>
  );
};

ArticlesTableRow.propTypes = {
  toggleEdit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default ArticlesTableRow;
