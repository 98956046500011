import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { Colors } from "../../styles";
import Fonts from "../../styles/Fonts";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import uploadFile from "../../rest/uploadFile";
import { AuthContext } from "../../context/auth-context";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_ARTICLE, UPDATE_ARTICLE } from "../../graphql/mutations";
import {
  GET_ARTICLE_BY_ID,
  GET_ARTICLE_CATEGORIES,
  GET_NEXT_AVAILABLE_ARTICLE_ID,
} from "../../graphql/queries";
import PropTypes from "prop-types";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import ArticleSchema from "../../validations/articleSchema";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import CustomEditor from "../../components/RichText/CustomEditor";
import htmlToEditorState from "../../utils/htmlToEditorState";
import fileUpload from "../../utils/fileUpload";
import editorStateToHtml from "../../utils/editorStateToHtml";

const useStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 100,
  },
  inputContainer: {
    marginTop: 40,
    position: "relative",
  },
  input: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    width: 400,
    "& input": {
      padding: 0,
    },
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
  button: {
    marginTop: 60,
  },
  errorMessage: {
    fontSize: Fonts.SIZES.MESSAGE_TEXT,
    color: Colors.COLOR_RED,
    position: "absolute",
    left: 0,
    bottom: -25,
  },
  disabledButton: {
    color: Colors.COLOR_WHITE + "!important",
  },
  uploadContainer: {
    display: "flex",
  },
  uploadedFile: {
    marginLeft: 25,
    width: 50,
    maxWidth: 50,
  },
  uploadLabel: {
    maxHeight: 50,
    display: "flex",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    height: 50,
    width: 250,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    color: Colors.COLOR_WHITE,
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    },
  },
  uploadField: {
    display: "none",
  },
}));

const ArticleForm = ({ onFormSubmit, articleId }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileWasUploaded, setFileWasUploaded] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const form = useRef();
  const { accessToken } = useContext(AuthContext);
  const [createArticleMutation] = useMutation(CREATE_ARTICLE);
  const [updateArticleMutation] = useMutation(UPDATE_ARTICLE);
  const { data: nextAvailableId } = useQuery(GET_NEXT_AVAILABLE_ARTICLE_ID, {
    fetchPolicy: "network-only",
  });
  const { data: articleCategories } = useQuery(GET_ARTICLE_CATEGORIES);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [englishEditorState, setEnglishEditorState] = useState(
    EditorState.createEmpty()
  );
  const [getArticleById, { data: article }] = useLazyQuery(GET_ARTICLE_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setFileUrl(`${data.article.imageUrl}`);
      setEditorState(htmlToEditorState(data.article.content));
      setEnglishEditorState(
        htmlToEditorState(data.article.englishContent)
      );
    },
  });

  useEffect(() => {
    if (articleId) {
      (async () => {
        await getArticleById({
          variables: {
            id: articleId,
          },
        });
      })();
    }
  }, [getArticleById, articleId]);

  const createArticle = async (values) => {
    console.log("ENV BASE_URL:", process.env.REACT_APP_BASE_URL);
    try {
      const fileResponse = await uploadFile(
        new FormData(form.current),
        accessToken
      );
      console.log("fileResponse:", fileResponse);
      const uploadedFilePath = `${process.env.REACT_APP_BASE_URL}/${fileResponse.files[0].path}`;
      console.log("uploadedFilePath:", uploadedFilePath); 
      await createArticleMutation({
        variables: {
          id: nextAvailableId.articlesNextAvailableId.nextId,
          title: values.title,
          englishTitle: values.englishTitle,
          content: editorStateToHtml(editorState),
          englishContent: editorStateToHtml(englishEditorState),
          externalUrl: values.externalUrl,
          imageUrl: uploadedFilePath,
          articleCategoriesId: Number(values.articleCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  const updateArticle = async (values) => {
    console.log("ENV BASE_URL:", process.env.REACT_APP_BASE_URL);
    try {
      let uploadedFilePath = article.article.imageUrl;
      if (fileWasUploaded) {
        const fileResponse = await uploadFile(
          new FormData(form.current),
          accessToken
        );
        uploadedFilePath = `${process.env.REACT_APP_BASE_URL}/${fileResponse.files[0].path}`;
        console.log("uploadedFilePath:", uploadedFilePath);
      }
      await updateArticleMutation({
        variables: {
          id: articleId,
          title: values.title,
          englishTitle: values.englishTitle,
          content: editorStateToHtml(editorState),
          englishContent: editorStateToHtml(englishEditorState),
          externalUrl: values.externalUrl,
          imageUrl: uploadedFilePath,
          articleCategoriesId: Number(values.articleCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  return (
    <Box className={classes.formContainer}>
      <Formik
        initialValues={{
          title: article?.article?.title ?? "",
          englishTitle: article?.article?.englishTitle ?? "",
          content: article?.article?.content ?? "",
          englishContent: article?.article?.englishContent ?? "",
          externalUrl: article?.article?.externalUrl ?? "",
          imageUrl: article?.article?.imageUrl ?? "",
          articleCategoriesId: article?.article?.articleCategoriesId ?? 1,
        }}
        enableReinitialize={true}
        validationSchema={ArticleSchema}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true);
          if (articleId) {
            await updateArticle(values);
          } else {
            await createArticle(values);
          }
          formikHelpers.setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          isValid,
          isSubmitting,
          submitForm,
          setFieldValue,
          dirty,
        }) => (
          <Form ref={form}>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="title" className={classes.inputLabel}>
                {t("articles.form.title")}
              </InputLabel>
              <Field id="title" name="title" className={classes.input} />
              {errors.title && touched.title ? (
                <Box className={classes.errorMessage}>{t(errors.title)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="englishTitle" className={classes.inputLabel}>
                {t("articles.form.englishTitle")}
              </InputLabel>
              <Field
                id="englishTitle"
                name="englishTitle"
                className={classes.input}
              />
              {errors.englishTitle && touched.englishTitle ? (
                <Box className={classes.errorMessage}>
                  {t(errors.englishTitle)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="externalUrl" className={classes.inputLabel}>
                {t("articles.form.youtubeLink")}
              </InputLabel>
              <Field
                id="externalUrl"
                name="externalUrl"
                className={classes.input}
              />
              {errors.externalUrl && touched.externalUrl ? (
                <Box className={classes.errorMessage}>
                  {t(errors.externalUrl)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel
                htmlFor="articleCategoriesId"
                className={classes.inputLabel}
              >
                {t("articles.form.articleCategory")}
              </InputLabel>
              <Field
                id="articleCategoriesId"
                name="articleCategoriesId"
                className={classes.input}
                as="select"
              >
                {articleCategories?.articleCategories.map((articleCategory) => (
                  <option value={articleCategory.id} key={articleCategory.id}>
                    {articleCategory.name}
                  </option>
                ))}
              </Field>
              {errors.category && touched.category ? (
                <Box className={classes.errorMessage}>{t(errors.category)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <CustomEditor
                label={t("articles.form.content")}
                editorState={editorState}
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
                toolbar={{
                  image: {
                    uploadCallback: (file) => fileUpload(file, accessToken),
                    previewImage: true,
                  },
                }}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <CustomEditor
                label={t("articles.form.englishContent")}
                editorState={englishEditorState}
                onEditorStateChange={(editorState) =>
                  setEnglishEditorState(editorState)
                }
                toolbar={{
                  image: {
                    uploadCallback: (file) => fileUpload(file, accessToken),
                    previewImage: true,
                  },
                }}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <Box className={classes.uploadContainer}>
                <InputLabel htmlFor="imageUrl" className={classes.uploadLabel}>
                  {t("articles.form.image")}
                </InputLabel>
                <input
                  id="imageUrl"
                  name="imageUrl"
                  type="file"
                  accept="image/*"
                  className={classes.uploadField}
                  onChange={(event) => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setFieldValue("imageUrl", fileReader.result);
                        setFileUrl(fileReader.result);
                        setFileWasUploaded(true);
                      }
                    };
                    if (fileReader) {
                      fileReader.readAsDataURL(event.target.files[0]);
                    }
                  }}
                />
                {fileUrl && (
                  <img
                    className={classes.uploadedFile}
                    src={fileUrl}
                    alt="uploaded file"
                  />
                )}
              </Box>
              {errors.imageUrl && touched.imageUrl ? (
                <Box className={classes.errorMessage}>{t(errors.imageUrl)}</Box>
              ) : null}
            </Box>
            <PrimaryButton
              onClick={() => submitForm()}
              className={classes.button}
              disabled={!isValid || isSubmitting || (!dirty && !articleId)}
              disabledClass={classes.disabledButton}
            >
              {articleId
                ? t("articles.form.update")
                : t("articles.form.submit")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

ArticleForm.propTypes = {
  articleId: PropTypes.number,
  onFormSubmit: PropTypes.func.isRequired,
};

export default ArticleForm;
