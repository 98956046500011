import { gql } from "@apollo/client";

export default gql`
  query GetTipsTricksCategories {
    tipsTricksCategories {
      id
      name
      englishName
      iconUrl
      description
    }
  }
`;
