export const getLocalStorageValue = (key, defaultValue) => {
  const raw = localStorage.getItem(key);
  if (!raw) return defaultValue;

  try {
    // Försök tolka som JSON
    return JSON.parse(raw);
  } catch (e) {
    // Om det failar, returnera defaultValue
    return defaultValue;
  }
};

export const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorageValue = (key) => {
  localStorage.removeItem(key);
};

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};
