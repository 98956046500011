import uploadFile from "../rest/uploadFile";

const fileUpload = async (file, accessToken) => {
  const data = new FormData();
  data.append("image", file);
  const uploadedFile = await uploadFile(data, accessToken);
  return {
    data: {
      link: `${process.env.REACT_APP_BASE_URL}/${uploadedFile.files[0].path}`,
    },
  };
};

export default fileUpload;
