import { gql } from "@apollo/client";

export default gql`
  query GetRelaxAudios {
    relaxAudios(filter: { include: ["relaxAudioCategories"] }) {
      id
      trackName
      audioUrl
      relaxAudioCategoriesId
      relaxAudioCategories {
        name
      }
    }
  }
`;
