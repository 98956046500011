import { gql } from "@apollo/client";

export default gql`
  query GetTipsTricks {
    tipsTricks(filter: { include: ["tipsTricksCategories"] }) {
      id
      title
      tipsTricksCategories {
        name
      }
    }
  }
`;
