import * as Yup from "yup";

const TipsTricksCategoriesSchema = Yup.object().shape({
  name: Yup.string().required("tipsTricksCategories.form.nameValidation"),
  englishName: Yup.string().required(
    "tipsTricksCategories.form.englishNameValidation"
  ),
  iconUrl: Yup.string().required("tipsTricksCategories.form.iconUrlValidation"),
});

export default TipsTricksCategoriesSchema;
