import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "react-draft-wysiwyg";
import { Colors } from "../../styles";
import { Typography } from "@material-ui/core";
import Fonts from "../../styles/Fonts";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
  },
  label: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
}));

const CustomEditor = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <Editor {...props} wrapperClassName={classes.wrapper} />
    </>
  );
};

CustomEditor.propTypes = {
  label: PropTypes.string.isRequired,
};

export default CustomEditor;
