import React, { useEffect, useState } from "react";
import RelaxAudiosTable from "./RelaxAudiosTable";
import { Colors, Fonts } from "../../styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BaseInputField from "../../components/Inputs/BaseInput";
import TablePagination from "../../components/Common/TablePagination";
import { resultsPerPage } from "../../constants/pagination";
import { useQuery } from "@apollo/client";
import { GET_RELAX_AUDIOS } from "../../graphql/queries";
import { useTranslation } from "react-i18next";
import Relax from "../../components/Icons/Relax";
import RelaxAudioForm from "./RelaxAudioForm";

const useStyles = makeStyles(() => ({
  headerContainer: {
    margin: "40px 0",
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    fontWeight: 600,
    marginLeft: 25,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: 500,
    "& div": {
      marginTop: 0,
    },
  },
  contentContainer: {
    padding: "1rem 5rem",
    flex: 1,
    maxWidth: "70vw",
  },
  userContainer: {
    marginLeft: 300,
  },
}));

const RelaxAudios = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [relaxAudioData, setRelaxAudioData] = useState([]);
  const [originalRelaxAudioData, setOriginalRelaxAudioData] = useState([]);
  const [maxPages, setMaxPages] = useState(0);
  const { t } = useTranslation();
  const [createRelaxAudio, setCreateRelaxAudio] = useState(false);
  const [editingRelaxAudioId, setEditingRelaxAudioId] = useState(null);
  const { data: relaxAudios, refetch: refetchRelaxAudios } =
    useQuery(GET_RELAX_AUDIOS);

  useEffect(() => {
    document.title = t("relaxAudios.title");
  }, [t]);

  useEffect(() => {
    setOriginalRelaxAudioData(
      relaxAudios ? JSON.parse(JSON.stringify(relaxAudios.relaxAudios)) : []
    );
    setRelaxAudioData(
      relaxAudios ? JSON.parse(JSON.stringify(relaxAudios.relaxAudios)) : []
    );
    setMaxPages(relaxAudios ? relaxAudios.relaxAudios.length : 1);
    setCurrentPage(1);
  }, [relaxAudios]);

  useEffect(() => {
    const startIndex =
      currentPage === 1 ? 0 : (currentPage - 1) * resultsPerPage;
    const endIndex =
      currentPage === 1 ? resultsPerPage : currentPage * resultsPerPage;
    if (!searchValue.trim()) {
      setRelaxAudioData(
        JSON.parse(
          JSON.stringify(originalRelaxAudioData.slice(startIndex, endIndex))
        )
      );
      setMaxPages(Math.ceil(originalRelaxAudioData.length / resultsPerPage));
    } else {
      const searchedValues = originalRelaxAudioData.filter((data) => {
        return (
          data.trackName.indexOf(searchValue) > -1 ||
          (data?.relaxAudioCategories
            ? data?.relaxAudioCategories.name.indexOf(searchValue) > -1
            : false)
        );
      });
      setRelaxAudioData(searchedValues.slice(startIndex, endIndex));
      setMaxPages(Math.ceil(searchedValues.length / resultsPerPage));
    }
  }, [currentPage, originalRelaxAudioData, searchValue, maxPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  const handlePrevPageClick = () => {
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prevState) => prevState + 1);
  };

  const onFormSubmit = async () => {
    await refetchRelaxAudios();
    setCreateRelaxAudio(false);
    setEditingRelaxAudioId(null);
  };

  return (
    <Box className={classes.userContainer}>
      <Grid container>
        <Box className={classes.contentContainer}>
          <Grid container className={classes.headerContainer}>
            <Relax height={40} width={40} color={Colors.COLOR_PRIMARY_BUTTON} />
            <Typography className={classes.title}>
              {createRelaxAudio
                ? t("relaxAudios.createNewHeader")
                : t("relaxAudios.header")}
            </Typography>
          </Grid>
          {createRelaxAudio || editingRelaxAudioId ? (
            <RelaxAudioForm
              onFormSubmit={onFormSubmit}
              relaxAudioId={editingRelaxAudioId}
            />
          ) : (
            <>
              <Box className={classes.filterContainer}>
                <Box className={classes.inputContainer}>
                  <BaseInputField
                    id="search-value"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder={t("relaxAudios.searchPlaceholder")}
                  />
                </Box>
                <PrimaryButton onClick={() => setCreateRelaxAudio(true)}>
                  {t("relaxAudios.addNewButton")}
                </PrimaryButton>
              </Box>
              <RelaxAudiosTable
                toggleEdit={(id) => setEditingRelaxAudioId(id)}
                results={relaxAudioData.map((relaxAudio) => {
                  return {
                    id: relaxAudio.id,
                    title: relaxAudio.trackName,
                    category: relaxAudio?.relaxAudioCategories?.name,
                  };
                })}
              />
              <TablePagination
                prevDisabled={currentPage === 1}
                nextDisabled={currentPage >= maxPages}
                onPrevPageClick={handlePrevPageClick}
                onNextPageClick={handleNextPageClick}
              />
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default RelaxAudios;
