import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TipsTricks from "../../components/Icons/TipsTricks";
import Articles from "../../components/Icons/Articles";
import Relax from "../../components/Icons/Relax";

const useStyles = makeStyles(() => ({
  sidebar: {
    borderRight: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    borderBottom: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    padding: 30,
    minHeight: "100vh",
    backgroundColor: Colors.COLOR_WHITE,
    position: "absolute",
    top: 94,
    left: 0,
    width: 250,
  },
  sidebarItem: {
    display: "flex",
    alignItems: "center",
    marginTop: 25,
  },
  menuText: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    marginLeft: 15,
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Box className={classes.sidebar}>
      <NavLink to="/tips-tricks-categories">
        <Box className={classes.sidebarItem}>
          <TipsTricks
            height={24}
            width={24}
            color={
              location.pathname === "/tips-tricks-categories"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.tipsTricksCategories")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/tips-tricks">
        <Box className={classes.sidebarItem}>
          <TipsTricks
            height={24}
            width={24}
            color={
              location.pathname === "/tips-tricks"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.tipsTricks")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/article-categories">
        <Box className={classes.sidebarItem}>
          <Articles
            height={24}
            width={24}
            color={
              location.pathname === "/article-categories"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.articleCategories")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/articles">
        <Box className={classes.sidebarItem}>
          <Articles
            height={24}
            width={24}
            color={
              location.pathname === "/articles"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.articles")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/article-videos">
        <Box className={classes.sidebarItem}>
          <Articles
            height={24}
            width={24}
            color={
              location.pathname === "/article-videos"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.articleVideos")}
          </Typography>
        </Box>
      </NavLink>
      <NavLink to="/relax">
        <Box className={classes.sidebarItem}>
          <Relax
            height={24}
            width={24}
            color={
              location.pathname === "/relax"
                ? Colors.COLOR_PRIMARY_BUTTON
                : Colors.COLOR_GRAY
            }
          />
          <Typography className={classes.menuText}>
            {t("sidebar.sounds")}
          </Typography>
        </Box>
      </NavLink>
    </Box>
  );
};

export default Sidebar;
