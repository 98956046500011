import * as Yup from "yup";

const ArticleSchema = Yup.object().shape({
  title: Yup.string().required("articles.form.titleValidation"),
  englishTitle: Yup.string(),
  content: Yup.string(),
  englishContent: Yup.string(),
  externalUrl: Yup.string(),
  imageUrl: Yup.string().required("articles.form.imageValidation"),
  articleCategoriesId: Yup.number().required(
    "articles.form.articleCategoryValidation"
  ),
});

export default ArticleSchema;
