import { gql } from "@apollo/client";

export default gql`
  mutation UpdateArticleCategory(
    $id: Float!
    $name: String!
    $englishName: String!
    $iconUrl: String!
  ) {
    patchArticleCategory(
      id: $id
      articleCategoriesPartialInput: {
        name: $name
        englishName: $englishName
        iconUrl: $iconUrl
      }
    ) {
      id
    }
  }
`;
