import { gql } from "@apollo/client";

export default gql`
  query GetArticleById($id: Float!) {
    article(id: $id) {
      id
      title
      englishTitle
      content
      englishContent
      externalUrl
      imageUrl
      articleTypesId
      articleCategoriesId
      isCoaching
    }
  }
`;
