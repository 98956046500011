import { gql } from "@apollo/client";

export default gql`
  mutation UpdateRelaxAudio(
    $id: Float!
    $audioUrl: String!
    $relaxAudioCategoriesId: Float!
    $trackName: String!
  ) {
    patchRelaxAudio(
      id: $id
      relaxAudiosPartialInput: {
        audioUrl: $audioUrl
        relaxAudioCategoriesId: $relaxAudioCategoriesId
        trackName: $trackName
      }
    ) {
      id
    }
  }
`;
