import * as Yup from "yup";

const ArticleVideosSchema = Yup.object().shape({
  title: Yup.string().required("articleVideos.form.nameValidation"),
  videoUrl: Yup.string().required("articleVideos.form.videoUrlValidation"),
  articleCategoriesId: Yup.number().required(
    "articleVideos.form.categoryValidation"
  ),
});

export default ArticleVideosSchema;
