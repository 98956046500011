import { gql } from "@apollo/client";

export default gql`
  query GetRelaxAudioById($id: Float!) {
    relaxAudio(id: $id) {
      id
      trackName
      audioUrl
      relaxAudioCategoriesId
    }
  }
`;
