import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  tableHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: Colors.COLOR_WHITE,
    padding: "10px 40px 10px 40px",
    "& p": {
      fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
      fontWeight: 600,
    },
  },
  column: {
    flex: "30%",
  },
  columnSmall: {
    flex: "10%",
  },
}));

const TipsTricksCategoriesTableHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.tableHeaderContainer}>
      <Box className={classes.column}>
        <Typography>{t("tipsTricksCategories.category")}</Typography>
      </Box>
      <Box className={classes.column}>
        <Typography>{t("tipsTricksCategories.englishCategory")}</Typography>
      </Box>
      <Box className={classes.columnSmall}>
        <Typography>{t("tipsTricksCategories.icon")}</Typography>
      </Box>
    </Box>
  );
};

export default TipsTricksCategoriesTableHeader;
