import { gql } from "@apollo/client";

export default gql`
  mutation CreateArticleVideo(
    $id: Float!
    $title: String!
    $videoUrl: String!
    $articleCategoriesId: Float!
  ) {
    postArticleVideos(
      newArticleVideoInput: {
        id: $id
        title: $title
        videoUrl: $videoUrl
        articleTypesId: 2
        articleCategoriesId: $articleCategoriesId
      }
    ) {
      id
    }
  }
`;
