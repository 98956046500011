import { gql } from "@apollo/client";

export default gql`
  mutation UploadFile($personalNumber: String!) {
    postUserAdministratorBankId(
      userAdministratorBankIdInput: { personalNumber: $personalNumber }
    ) {
      accessToken
      expiresAt
      refreshToken
      refreshExpiresAt
      user {
        id
        email
        isActive
        socialSecurityNumber
        school {
          id
          name
        }
      }
    }
  }
`;
