import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { Colors } from "../../styles";
import Fonts from "../../styles/Fonts";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import uploadFile from "../../rest/uploadFile";
import { AuthContext } from "../../context/auth-context";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_TIPS_TRICKS_CATEGORY,
  UPDATE_TIPS_TRICKS_CATEGORY,
} from "../../graphql/mutations";
import {
  GET_NEXT_AVAILABLE_TIPS_TRICKS_CATEGORY_ID,
  GET_TIPS_TRICKS_CATEGORY_BY_ID,
} from "../../graphql/queries";
import PropTypes from "prop-types";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import TipsTricksCategoriesSchema from "../../validations/tipsTricksCategoriesSchema";

const useStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 100,
  },
  inputContainer: {
    marginTop: 40,
    position: "relative",
  },
  input: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    width: 400,
    "& input": {
      padding: 0,
    },
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
  button: {
    marginTop: 60,
  },
  errorMessage: {
    fontSize: Fonts.SIZES.MESSAGE_TEXT,
    color: Colors.COLOR_RED,
    position: "absolute",
    left: 0,
    bottom: -25,
  },
  disabledButton: {
    color: Colors.COLOR_WHITE + "!important",
  },
  uploadContainer: {
    display: "flex",
  },
  uploadedFile: {
    marginLeft: 25,
    width: 50,
    maxWidth: 50,
  },
  uploadLabel: {
    maxHeight: 50,
    display: "flex",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    height: 50,
    width: 250,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    color: Colors.COLOR_WHITE,
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    },
  },
  uploadField: {
    display: "none",
  },
}));

const TipsTricksCategoriesForm = ({ onFormSubmit, tipsTricksCategoryId }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileWasUploaded, setFileWasUploaded] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const form = useRef();
  const { accessToken } = useContext(AuthContext);
  const [createTipsTricksCategoryMutation] = useMutation(
    CREATE_TIPS_TRICKS_CATEGORY
  );
  const [updateTipsTricksCategoryMutation] = useMutation(
    UPDATE_TIPS_TRICKS_CATEGORY
  );
  const { data: nextAvailableId } = useQuery(
    GET_NEXT_AVAILABLE_TIPS_TRICKS_CATEGORY_ID,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getTipsTricksCategoryById, { data: tipsTricksCategory }] =
    useLazyQuery(GET_TIPS_TRICKS_CATEGORY_BY_ID, {
      onCompleted: (data) => {
        setFileUrl(
          `${process.env.REACT_APP_BASE_URL}/${data.tipsTricksCategory.iconUrl}`
        );
      },
    });

  useEffect(() => {
    if (tipsTricksCategoryId) {
      (async () => {
        await getTipsTricksCategoryById({
          variables: {
            id: tipsTricksCategoryId,
          },
        });
      })();
    }
  }, [getTipsTricksCategoryById, tipsTricksCategoryId]);

  const createTipsTricksCategory = async (values) => {
    try {
      const fileResponse = await uploadFile(
        new FormData(form.current),
        accessToken
      );
      const uploadedFilePath = fileResponse.files[0].path;
      await createTipsTricksCategoryMutation({
        variables: {
          id: nextAvailableId.tipsTricksCategoriesNextAvailableId.nextId,
          name: values.name,
          englishName: values.englishName,
          iconUrl: uploadedFilePath,
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  const updateTipsTricksCategory = async (values) => {
    try {
      let uploadedFilePath = tipsTricksCategory.tipsTricksCategory.iconUrl;
      if (fileWasUploaded) {
        const fileResponse = await uploadFile(
          new FormData(form.current),
          accessToken
        );
        uploadedFilePath = fileResponse.files[0].path;
      }
      await updateTipsTricksCategoryMutation({
        variables: {
          id: tipsTricksCategoryId,
          name: values.name,
          englishName: values.englishName,
          iconUrl: uploadedFilePath,
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  return (
    <Box className={classes.formContainer}>
      <Formik
        initialValues={{
          name: tipsTricksCategory?.tipsTricksCategory?.name ?? "",
          englishName:
            tipsTricksCategory?.tipsTricksCategory?.englishName ?? "",
          iconUrl: tipsTricksCategory?.tipsTricksCategory?.iconUrl ?? "",
        }}
        enableReinitialize={true}
        validationSchema={TipsTricksCategoriesSchema}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true);
          if (tipsTricksCategoryId) {
            await updateTipsTricksCategory(values);
          } else {
            await createTipsTricksCategory(values);
          }
          formikHelpers.setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          isValid,
          isSubmitting,
          submitForm,
          setFieldValue,
          dirty,
        }) => (
          <Form ref={form}>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="name" className={classes.inputLabel}>
                {t("tipsTricksCategories.form.name")}
              </InputLabel>
              <Field id="name" name="name" className={classes.input} />
              {errors.name && touched.name ? (
                <Box className={classes.errorMessage}>{t(errors.name)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="englishName" className={classes.inputLabel}>
                {t("tipsTricksCategories.form.englishName")}
              </InputLabel>
              <Field
                id="englishName"
                name="englishName"
                className={classes.input}
              />
              {errors.englishName && touched.englishName ? (
                <Box className={classes.errorMessage}>
                  {t(errors.englishName)}
                </Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <Box className={classes.uploadContainer}>
                <InputLabel htmlFor="iconUrl" className={classes.uploadLabel}>
                  {t("tipsTricksCategories.form.icon")}
                </InputLabel>
                <input
                  id="iconUrl"
                  name="iconUrl"
                  type="file"
                  accept="image/*"
                  className={classes.uploadField}
                  onChange={(event) => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setFieldValue("iconUrl", fileReader.result);
                        setFileUrl(fileReader.result);
                        setFileWasUploaded(true);
                      }
                    };
                    if (fileReader) {
                      fileReader.readAsDataURL(event.target.files[0]);
                    }
                  }}
                />
                {fileUrl && (
                  <img
                    className={classes.uploadedFile}
                    src={fileUrl}
                    alt="uploaded file"
                  />
                )}
              </Box>
              {errors.iconUrl && touched.iconUrl ? (
                <Box className={classes.errorMessage}>{t(errors.iconUrl)}</Box>
              ) : null}
            </Box>
            <PrimaryButton
              onClick={() => submitForm()}
              className={classes.button}
              disabled={
                !isValid || isSubmitting || (!dirty && !tipsTricksCategoryId)
              }
              disabledClass={classes.disabledButton}
            >
              {tipsTricksCategoryId
                ? t("tipsTricksCategories.form.update")
                : t("tipsTricksCategories.form.submit")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

TipsTricksCategoriesForm.propTypes = {
  tipsTricksCategoryId: PropTypes.number,
  onFormSubmit: PropTypes.func.isRequired,
};

export default TipsTricksCategoriesForm;
