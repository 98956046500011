import React, { useEffect } from "react";
import { Box, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { Colors } from "../../styles";
import Fonts from "../../styles/Fonts";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_ARTICLE_VIDEO,
  UPDATE_ARTICLE_VIDEO,
} from "../../graphql/mutations";
import {
  GET_ARTICLE_CATEGORIES,
  GET_ARTICLE_VIDEO_BY_ID,
  GET_NEXT_AVAILABLE_ARTICLE_VIDEO_ID,
} from "../../graphql/queries";
import PropTypes from "prop-types";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import ArticleVideosSchema from "../../validations/articleVideosSchema";
import formatYouTubeLinks from "../../utils/formatYouTubeLinks";

const useStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 100,
  },
  inputContainer: {
    marginTop: 40,
    position: "relative",
  },
  input: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    width: 400,
    "& input": {
      padding: 0,
    },
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
  button: {
    marginTop: 60,
  },
  errorMessage: {
    fontSize: Fonts.SIZES.MESSAGE_TEXT,
    color: Colors.COLOR_RED,
    position: "absolute",
    left: 0,
    bottom: -25,
  },
  disabledButton: {
    color: Colors.COLOR_WHITE + "!important",
  },
}));

const ArticleCategoriesForm = ({ onFormSubmit, articleVideoId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createArticleVideoMutation] = useMutation(CREATE_ARTICLE_VIDEO);
  const [updateArticleVideoMutation] = useMutation(UPDATE_ARTICLE_VIDEO);
  const { data: nextAvailableId } = useQuery(
    GET_NEXT_AVAILABLE_ARTICLE_VIDEO_ID,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getArticleVideoById, { data: articleVideo }] = useLazyQuery(
    GET_ARTICLE_VIDEO_BY_ID
  );
  const { data: articleCategories } = useQuery(GET_ARTICLE_CATEGORIES);

  useEffect(() => {
    if (articleVideoId) {
      (async () => {
        await getArticleVideoById({
          variables: {
            id: articleVideoId,
          },
        });
      })();
    }
  }, [getArticleVideoById, articleVideoId]);

  const createArticleVideo = async (values) => {
    try {
      await createArticleVideoMutation({
        variables: {
          id: nextAvailableId.articleVideosNextAvailableId.nextId,
          title: values.title,
          videoUrl: formatYouTubeLinks(values.videoUrl),
          articleCategoriesId: Number(values.articleCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  const updateArticleVideo = async (values) => {
    try {
      await updateArticleVideoMutation({
        variables: {
          id: articleVideoId,
          title: values.title,
          videoUrl: formatYouTubeLinks(values.videoUrl),
          articleCategoriesId: Number(values.articleCategoriesId),
        },
      });
      onFormSubmit();
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
  };

  return (
    <Box className={classes.formContainer}>
      <Formik
        initialValues={{
          title: articleVideo?.articleVideo?.title ?? "",
          videoUrl: articleVideo?.articleVideo?.videoUrl ?? "",
          articleCategoriesId:
            articleVideo?.articleVideo?.articleCategoriesId ?? 1,
        }}
        enableReinitialize={true}
        validationSchema={ArticleVideosSchema}
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setSubmitting(true);
          if (articleVideoId) {
            await updateArticleVideo(values);
          } else {
            await createArticleVideo(values);
          }
          formikHelpers.setSubmitting(false);
        }}
      >
        {({ errors, touched, isValid, isSubmitting, submitForm, dirty }) => (
          <Form>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="title" className={classes.inputLabel}>
                {t("articleVideos.form.title")}
              </InputLabel>
              <Field id="title" name="title" className={classes.input} />
              {errors.title && touched.title ? (
                <Box className={classes.errorMessage}>{t(errors.title)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel htmlFor="videoUrl" className={classes.inputLabel}>
                {t("articleVideos.form.videoUrl")}
              </InputLabel>
              <Field id="videoUrl" name="videoUrl" className={classes.input} />
              {errors.videoUrl && touched.videoUrl ? (
                <Box className={classes.errorMessage}>{t(errors.videoUrl)}</Box>
              ) : null}
            </Box>
            <Box className={classes.inputContainer}>
              <InputLabel
                htmlFor="articleCategoriesId"
                className={classes.inputLabel}
              >
                {t("articleVideos.form.category")}
              </InputLabel>
              <Field
                id="articleCategoriesId"
                name="articleCategoriesId"
                className={classes.input}
                as="select"
              >
                {articleCategories?.articleCategories.map((articleCategory) => (
                  <option value={articleCategory.id} key={articleCategory.id}>
                    {articleCategory.name}
                  </option>
                ))}
              </Field>
              {errors.category && touched.category ? (
                <Box className={classes.errorMessage}>{t(errors.category)}</Box>
              ) : null}
            </Box>
            <PrimaryButton
              onClick={() => submitForm()}
              className={classes.button}
              disabled={!isValid || isSubmitting || (!dirty && !articleVideoId)}
              disabledClass={classes.disabledButton}
            >
              {articleVideoId
                ? t("articleVideos.form.update")
                : t("articleVideos.form.submit")}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

ArticleCategoriesForm.propTypes = {
  articleVideoId: PropTypes.number,
  onFormSubmit: PropTypes.func.isRequired,
};

export default ArticleCategoriesForm;
